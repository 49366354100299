// React
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TableFooter,
} from "@mui/material";

// Material UI Icons
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
} from "@mui/icons-material";

// Functions
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const MpsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "id", label: t("id") },
      {
        id: "timestamp",
        label: t("generation_datetime"),
      },
      { id: "value", label: t("value") },
      { id: "uom", label: t("measure_unit") },
      { id: "magnitude", label: t("magnitude") },
      { id: "visibility", label: t("visibility") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id !== "actions" ? "left" : "right"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) =>
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {isRtl ? (
          <KeyboardArrowRightOutlinedIcon />
        ) : (
          <KeyboardArrowLeftOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {isRtl ? (
          <KeyboardArrowLeftOutlinedIcon />
        ) : (
          <KeyboardArrowRightOutlinedIcon />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MpsTable = ({
  records,
  selectedSensors,
  checkVerification,
  handleView,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}) => {
  console.log(records);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState("");

  useEffect(() => {
    if (selectedSensor) {
      const filteredRecords = records.reduce((acc, record) => {
        if (record.sensorId === selectedSensor) {
          record.magnitudes.forEach((mag) => acc.push(...mag.records));
        }
        return acc;
      }, []);

      let reversed = [];

      for (let i = filteredRecords.length - 1; i >= 0; i--) {
        reversed.push(filteredRecords[i]);
      }

      setFilteredRecords(reversed);
    } else {
      setFilteredRecords([]);
    }
  }, [selectedSensor, records]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSensor = (event) => {
    setSelectedSensor(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredRecords.length)
      : 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <FormControl fullWidth>
          <InputLabel id="Source Name">{t("source_name")}</InputLabel>
          <Select
            labelId={t("source_name")}
            id="Source Name"
            value={selectedSensor}
            onChange={handleChangeSensor}
            input={<OutlinedInput label={t("source_name")} />}
          >
            {selectedSensors.map((sensorId) => {
              const sensor = records.find(
                (record) => record.sensorId === sensorId
              );
              return (
                <MenuItem key={sensorId} value={sensorId}>
                  {sensor ? sensor.sensorName : sensorId}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <MpsTableHead />
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRecords.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRecords
              ).map((record) => {
                return (
                  <TableRow
                    key={record.id}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    {isMobile ? (
                      <TableCell align="left">
                        {record.id.substring(0, 6)}
                      </TableCell>
                    ) : (
                      <TableCell align="left">{record.id}</TableCell>
                    )}
                    <TableCell align="left">
                      {record.data.timestamp
                        ? convertTimestamp(record.data.timestamp)
                        : "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.value || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.uom || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.data.magnitude || "N/A"}
                    </TableCell>
                    <TableCell align="left">
                      {record.public ? t("public") : t("private")}
                    </TableCell>
                    <TableCell align="right" size="small">
                      <Button onClick={() => handleView(record)}>
                        {t("details")}
                      </Button>
                      <Button onClick={() => checkVerification(record)}>
                        {t("verify")}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                    colSpan={7}
                    count={filteredRecords.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) =>
                      t("displayed_rows", {
                        from,
                        to,
                        count: count !== -1 ? count : `more than ${to}`,
                      })
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TablePaginationActions
                count={filteredRecords.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
              />
            </Box>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default MpsTable;
