// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Functions
import postNewUser from "UserOperations/postNewUser";

// A ---------------------------------------------------------------------- M

const NewUser = () => {
  const { clientData } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [canCreateDatabox, setCanCreateDatabox] = useState(false);
  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [canAddUser, setCanAddUser] = useState(false);
  const [conservSostL1, setConservSostL1] = useState(false);

  const handleSave = async () => {
    await postNewUser(
      email,
      clientData ? clientData.id : "",
      canCreateDatabox,
      canCreateGroup,
      canSign,
      isReseller,
      isInternal,
      canAddUser,
      conservSostL1
    );

    navigate("/");
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          mt: isMobile ? 10 : 20,
          p: isMobile ? 2 : 4,
          boxShadow: 4,
          borderRadius: 2,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h5"}
          fontWeight="bold"
          mb="5%"
          align="center"
        >
          {t("add_new_user")}
        </Typography>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="Email Address"
              label={t("email")}
              name="Email Address"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-label={t("email")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="Customer Name"
              label={t("customer_name")}
              name="Customer Name"
              variant="outlined"
              value={
                clientData ? clientData.companyName || clientData.id : "N/A"
              }
              aria-label={t("customer_name")}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canCreateDatabox}
                      onChange={(e) => setCanCreateDatabox(e.target.checked)}
                    />
                  }
                  label="canCreateDatabox"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canCreateGroup}
                      onChange={(e) => setCanCreateGroup(e.target.checked)}
                    />
                  }
                  label="canCreateGroup"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canSign}
                      onChange={(e) => setCanSign(e.target.checked)}
                    />
                  }
                  label="canSign"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isReseller}
                      onChange={(e) => setIsReseller(e.target.checked)}
                    />
                  }
                  label="isReseller"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={canAddUser}
                      onChange={(e) => setCanAddUser(e.target.checked)}
                    />
                  }
                  label="canAddUser"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conservSostL1}
                      onChange={(e) => setConservSostL1(e.target.checked)}
                    />
                  }
                  label="conservSostL1"
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSave}
              aria-label={t("save")}
              sx={{ py: 1.5 }}
            >
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default NewUser;
