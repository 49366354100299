// React
import React, { useEffect, useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// Components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// Functions
import editClientPolicy from "CustomerOperations/editClientPolicy";

// A ---------------------------------------------------------------------- M

const EditClientPolicy = ({ client, agreement, open, setOpen }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [documentClass, setDocumentClass] = useState("");
  const [condition, setCondition] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    setDocumentClass(agreement.id);
    setCondition(agreement.condition);
  }, [agreement]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await editClientPolicy(client, documentClass, condition);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  const editForm = (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          margin="dense"
          id="documentClass"
          name="documentClass"
          label={t("document_class")}
          required
          variant="outlined"
          value={documentClass}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormControl fullWidth sx={{ mt: "1%", mb: "2%" }}>
          <InputLabel id="condition-select-label-edit">
            {t("condition")}
          </InputLabel>
          <Select
            labelId="condition-select-label-edit"
            id="condition-edit"
            label={t("condition")}
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
          >
            <MenuItem value={"end_of_month"}>End of month</MenuItem>
            <MenuItem value={"end_of_year"}>End of year</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const DialogTitleContent = () => (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item>
        <Typography variant="h5" fontWeight="bold">
          {t("edit_policy")}
        </Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={() => setOpen(false)} sx={{ color: "red" }}>
          <CloseOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "70%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container justifyContent="center">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("edit_policy")}
            </Typography>
          </Grid>
        </Grid>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <>
            {editForm}
            <Grid container mt="3%">
              <Grid item xs={12}>
                <Button fullWidth variant="contained" onClick={handleSave}>
                  {t("save")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <DialogTitleContent />
      </DialogTitle>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <DialogContent dividers>
          {editForm}
          <Grid item container xs={12} mt="3%">
            <Grid item xs={12}>
              <Button fullWidth variant="contained" onClick={handleSave}>
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default EditClientPolicy;
