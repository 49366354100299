// React
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// A ---------------------------------------------------------------------- M

const AccountStatus = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="h6"
        p={isMobile ? "3%" : "0%"}
        fontWeight="bold"
        gutterBottom
      >
        {t("account_status")}
      </Typography>
      <Divider />
      <CardContent>
        {true && (
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant={isMobile ? "body2" : "body1"}
                color="green"
                fontWeight="bold"
                gutterBottom
              >
                {t("service_active")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Box>
  );
};

export default AccountStatus;
