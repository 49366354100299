// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// Functions
import getUserContacts from "UserOperations/getUserContacts";

// A ---------------------------------------------------------------------- M

/**
 * Fetches group details including creator's email.
 *
 * @param {string} groupID - The ID of the group to retrieve.
 * @returns {object} Group details including creator's email or an empty object on error.
 */
const getGroup = async (groupID) => {
  try {
    const groupRef = doc(db, "groupsdata", groupID);
    const group = await getDoc(groupRef);
    const groupData = group.data();
    const creator_uuid = groupData.creator_uuid;
    const userContacts = await getUserContacts(creator_uuid);
    const creator_email = userContacts.email || "N/A";

    console.log(groupData);

    return {
      id: groupID,
      creator_email: creator_email,
      ...groupData,
    };
  } catch (e) {
    console.error("Error in getGroup:", e.message);
    return {};
  }
};

export default getGroup;
