// React
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// A ---------------------------------------------------------------------- M

const ClientDetails = ({ client }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="h6"
        p={isMobile ? "3%" : "0%"}
        fontWeight="bold"
        gutterBottom
      >
        {t("customer_details")}
      </Typography>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("company")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("company_name")}</b>: {client.companyName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("tax_code")}</b>: {client.taxCode || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("vat_number")}</b>: {client.VATNumber || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("registration_number")}</b>:{" "}
                  {client.registrationNumber || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("email")}</b>: {client.email || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("phone")}</b>: {client.phone || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("contact_person")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("first_name")}</b>:{" "}
                  {client.contactPerson.firstName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("last_name")}</b>:{" "}
                  {client.contactPerson.lastName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("email")}</b>: {client.contactPerson.email || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("phone")}</b>: {client.contactPerson.phone || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("address")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("street")}</b>: {client.address.street || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("city")}</b>: {client.address.city || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("postal_code")}</b>:{" "}
                  {client.address.postalCode || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("payment_information")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("payment_terms")}</b>: {client.paymentTerms || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("tax_regime")}</b>: {client.taxRegime || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("bank_account")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("bank_name")}</b>:{" "}
                  {client.bankAccountDetails.bankName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("account_number")}</b>:{" "}
                  {client.bankAccountDetails.accountNumber || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("iban")}</b>: {client.bankAccountDetails.IBAN || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("swift")}</b>:{" "}
                  {client.bankAccountDetails.SWIFT || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("other_information")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("industry")}</b>: {client.industry || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("size")}</b>: {client.size || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("notes")}</b>: {client.notes || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default ClientDetails;
