// React
import React from "react";

// Contexts
import { UserAuth } from "context/AuthContext";

// reactflow
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

// Material UI Components
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

// Material UI Icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

// A ---------------------------------------------------------------------- M

const AuthorizedUsersNode = ({ data }) => {
  const { conservSostL1 } = UserAuth();

  const getPermissions = (source) => {
    if (source.includes("read/write")) {
      return "ALL"; // Privilege read/write
    } else if (source.includes("read-only")) {
      return "R"; // Read-only
    } else {
      return "N/A"; // Fallback, should rarely happen
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 1,
        borderRadius: 2,
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography variant="h6" align="center" gutterBottom>
        <b>Authorized Users</b>
      </Typography>
      {/* Total Number of Certifications */}
      <Box mb={2} textAlign="center">
        <Typography variant="body1">
          <b>
            Tot. N. {!conservSostL1 ? "Certifications" : "Uploads"}:{" "}
            {data.totalNumberOfCertifications}
          </b>
        </Typography>
      </Box>
      {/* Users Data */}
      <Table size="small">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#e0e0e0" }}>
            <TableCell>
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Email
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Permissions
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                N. {!conservSostL1 ? "Certifications" : "Uploads"}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                Stream
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.authorizedUsersArray.map((user, index) => (
            <TableRow
              key={index}
              sx={{
                "&:nth-of-type(odd)": { backgroundColor: "#fafafa" },
                "&:nth-of-type(even)": { backgroundColor: "#f0f0f0" },
              }}
            >
              <TableCell>{user.user_email}</TableCell>
              <TableCell align="center">
                {getPermissions(user.source)}
              </TableCell>
              <TableCell align="center">
                {user.user_number_of_certifications}
              </TableCell>
              <TableCell align="center">
                {user.user_number_of_certifications > 0 ? (
                  <CheckCircleOutlineIcon
                    sx={{
                      color: "#00c853",
                      verticalAlign: "middle",
                      marginLeft: "5px",
                    }}
                  />
                ) : (
                  <CancelIcon
                    sx={{
                      color: "#b0b8b0",
                      verticalAlign: "middle",
                      marginLeft: "5px",
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Handle type="source" position={Position.Right} id="a" />
    </Paper>
  );
};

export default AuthorizedUsersNode;
