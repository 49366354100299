// React
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Contexts
import { UserAuth } from "context/AuthContext";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";

// Components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import {
  NewCertConfirm,
  NewRequestConfirm,
  InvalidFile,
} from "ui-components/ORFeedbacks";
import CDNFileForm from "./CDNFileForm";
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";

// Functions
import fileToUint8Array from "utils/fileToUint8Array";
import getFile from "storage/getFile";
import uploadFile from "storage/uploadFile";
import genCDNFileRecord from "generator/CDNFileGenerator/genCDNFileRecord";
import completeCertificationRequest from "tag/completeCertificationRequest";
import getUserContacts from "UserOperations/getUserContacts";

// SafeTwin
import { calculateSHA256, toHex } from "SafeTwin/crypto/cryptolibsodium";

// Firebase
import { db } from "config/firebase";
import { onSnapshot, doc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const initialDocumentProperties = {
  nomeFile: "",
  formatoFile: "",
  nomeFileDestinazione: "",
  cartellaDestinazione: "",
  classeDocumentale: "",
  dataDocumento: "",
  impronta: "",
  algoritmo: "",
  identificativoDocumento: "",
  versioneDocumento: "",
  modalitaFormazione: "",
  tipologiaFlusso: "",
  tipoRegistro: "",
  dataRegistrazione: "",
  numeroDocumento: "",
  codiceRegistro: "",
  oggetto: "",
  ruolo: "",
  tipoSoggetto: "",
  cognome: "",
  nome: "",
  denominazione: "",
  codiceFiscale: "",
  indirizziDigitaliDiRiferimento: "",
  allegatiNumero: "",
  idDocIndiceAllegati: "",
  descrizioneAllegati: "",
  indiceDiClassificazione: "",
  descrizioneClassificazione: "",
  riservato: "",
  pianoClassificazione: "",
  prodottoSoftwareNome: "",
  prodottoSoftwareVersione: "",
  prodottoSoftwareProduttore: "",
  verificaFirmaDigitale: "",
  verificaMarcaTemporale: "",
  verificaSigillo: "",
  verificaConformitaCopie: "",
  idAggregazione: "",
  identificativoDocumentoPrincipale: "",
  tracciaturaModificheTipo: "",
  soggettoAutoreModifica: "",
  tracciaturaModificheData: "",
  tracciaturaModificheIdDocVersionePrecedente: "",
  tempoConservazione: "",
  note: "",
};

const CDNFileGenerator = ({
  tags,
  tag,
  types,
  setTypes,
  open,
  setOpen,
  handleOpenCertificationSuccessful,
  handleOpenCertificationError,
  request,
  setRequest,
}) => {
  const { t } = useTranslation();
  const { user } = UserAuth();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [source, setSource] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const [downloadURL, setDownloadUrl] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState("");
  const [textContent, setTextContent] = useState("");
  const [documentProperties, setDocumentProperties] = useState(
    initialDocumentProperties
  );
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [tagSelectionOpen, setTagSelectionOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [approval, setApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [uploadReceiptTXID, setUploadReceiptTXID] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  // Handle request data
  useEffect(() => {
    if (request) {
      const { downloadURL, fileName, fileType } = request.data;
      setDownloadUrl(downloadURL);
      setFileName(fileName);
      setFileType(fileType);

      const fetchFile = async () => {
        const bytearray = await getFile(downloadURL);
        setSource(bytearray);
      };

      fetchFile();
    } else {
      handleResetFileData();
    }
  }, [request]);

  useEffect(() => {
    if (!uploadReceiptTXID || !user) return;

    const unsubscribe = onSnapshot(
      doc(db, "uploadReceipts", uploadReceiptTXID),
      async (doc) => {
        if (doc.exists()) {
          const receiptData = doc.data();
          if (receiptData.creator_uuid === user.uid) {
            const creatorContacts = await getUserContacts(
              receiptData.creator_uuid
            );
            const creatorEmail = creatorContacts.email || "N/A";
            setUploadReceipt({ ...receiptData, creatorEmail: creatorEmail });
            setReceiptDialogOpen(true);
          }
        }
      }
    );

    return () => unsubscribe();
  }, [uploadReceiptTXID, user]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    try {
      let downloadURL = request?.data?.downloadURL || "";
      let uploadID = null;

      if (!downloadURL) {
        const uploadResult = await uploadFile(source, fileName, fileType, tag);
        downloadURL = uploadResult.downloadURL;
        uploadID = uploadResult.uploadID;
      }

      if (downloadURL) {
        const dataBody = {
          source,
          downloadURL,
          uploadID,
          fileName,
          fileType,
          documentProperties,
        };

        const result = await genCDNFileRecord(
          user.uid,
          dataBody,
          selectedTag || tag
        );

        if (result.success) {
          if (request) {
            await completeCertificationRequest(tag, request.id);
          }

          setUploadReceiptTXID(result.txid);

          handleOpenCertificationSuccessful();

          if (types && !types.includes("CDNFile")) {
            setTypes([...types, "CDNFile"]);
          }
        } else {
          handleOpenCertificationError();
        }
      } else {
        console.error("Invalid file format");
        setIsInvalidFile(true);
        resetFileInput();
      }
    } catch (error) {
      console.error("Error in handleGenerate:", error.message);
      handleOpenCertificationError();
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const handleSendApprovalRequest = async () => {
    setIsLoading(true);
    console.log("Send approval request upload");
    setIsLoading(false);
    handleReset();
  };

  const handleDocumentPropertyChange = (prop) => (event) => {
    setDocumentProperties({
      ...documentProperties,
      [prop]: event.target.value,
    });
  };

  const handleFileChange = async (target) => {
    if (target.files && target.files.length) {
      const file = target.files[0];
      const validExtensions = ["pdf", "xml", "txt", "csv", "png"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const mimeType = file.type;

      if (
        validExtensions.includes(fileExtension) &&
        (mimeType.includes("application/pdf") ||
          mimeType.includes("text/xml") ||
          mimeType.includes("application/xml") ||
          mimeType.includes("text/plain") ||
          mimeType.includes("text/csv") ||
          mimeType.includes("image/png"))
      ) {
        const fileByteArray = await fileToUint8Array(file);
        const blobURL = URL.createObjectURL(file);
        setSource(fileByteArray);
        setBlobURL(blobURL);
        setFileName(file.name);
        setFileType(file.type);

        setDocumentProperties((prevProps) => ({
          ...prevProps,
          nomeFile: file.name,
          formatoFile: file.type,
          nomeFileDestinazione: file.name,
          impronta: toHex(calculateSHA256(fileByteArray)),
          algoritmo: "SHA-256",
        }));
      } else {
        console.error("Invalid file format");
        setIsInvalidFile(true);
        resetFileInput();
      }
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("new-upload-home-button");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleResetFileData = () => {
    setSource("");
    setBlobURL("");
    URL.revokeObjectURL(blobURL);
    setFileName("");
    setFileType("");
    setDownloadUrl("");
    setDocumentProperties(initialDocumentProperties);
    setSelectedTag("");
    setIsInvalidFile(false);
    setSelectedFile(null);
  };

  const handleReset = () => {
    handleResetFileData();

    if (request) {
      setRequest();
    }

    setOpen(false);
    resetFileInput();
  };

  const handleResetUploadReceipt = () => {
    setUploadReceiptTXID(null);
    setUploadReceipt(null);
    setReceiptDialogOpen(false);
  };

  const handleChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleOpen = () => {
    setTagSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleClose = () => {
    setTagSelectionOpen(false);
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileType = blob.type;

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType === "image/png") {
        setSelectedFile(fileUrl);
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error);
    }
  };

  const handleCloseDialogPDF = () => {
    setSelectedFile(null);
    setCurrentPage(1);
  };

  const handleCloseDialogImage = () => {
    setSelectedFile(null);
  };

  const handleCloseDialogXML = () => {
    setSelectedFile(null);
    setXmlContent("");
  };

  const handleCloseDialogText = () => {
    setSelectedFile(null);
    setTextContent("");
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderFileDialogs = () => {
    if (!selectedFile) return null;

    switch (fileType) {
      case "application/pdf":
        return (
          <Dialog open onClose={handleCloseDialogPDF} fullWidth maxWidth="md">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                sx={{ color: "red" }}
                onClick={handleCloseDialogPDF}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Document
              file={selectedFile}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              <Page
                pageNumber={currentPage}
                width={isMobile ? 300 : 600}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                aria-label="previous page"
              >
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === numPages}
                aria-label="next page"
              >
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog open onClose={handleCloseDialogXML} fullWidth maxWidth="md">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                sx={{ color: "red" }}
                onClick={handleCloseDialogXML}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <XMLViewer xml={xmlContent} />
            </Box>
          </Dialog>
        );

      case "text/plain":
        return (
          <Dialog open onClose={handleCloseDialogText} fullWidth maxWidth="md">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                sx={{ color: "red" }}
                onClick={handleCloseDialogText}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        const rows = textContent.split("\n").map((row) => row.split(","));
        return (
          <Dialog open onClose={handleCloseDialogText} fullWidth maxWidth="md">
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                sx={{ color: "red" }}
                onClick={handleCloseDialogText}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <table>
                <tbody>
                  {rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Dialog>
        );

      case "image/png":
        return (
          <Dialog open onClose={handleCloseDialogImage} fullWidth maxWidth="md">
            <Box sx={{ position: "relative" }}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "2%",
                  right: "2%",
                  color: "red",
                }}
                onClick={handleCloseDialogImage}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
              <img
                src={selectedFile}
                alt="Document"
                style={{ width: "100%" }}
              />
            </Box>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleReset}
          onOpen={() => setOpen(true)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: "5%" }}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              mt="5%"
              mb="10%"
            >
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("upload_document")}
                </Typography>
              </Grid>
            </Grid>
            {isLoading ? (
              <LinearLoadingComponent />
            ) : (
              <>
                <CDNFileForm
                  isMobile={isMobile}
                  request={request}
                  tags={tags}
                  location={location}
                  source={source}
                  downloadURL={downloadURL}
                  blobURL={blobURL}
                  fileName={fileName}
                  fileType={fileType}
                  documentProperties={documentProperties}
                  tagSelectionOpen={tagSelectionOpen}
                  selectedTag={selectedTag}
                  handleDocumentPropertyChange={handleDocumentPropertyChange}
                  handleFileChange={handleFileChange}
                  handleChange={handleChange}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  handleFileClick={handleFileClick}
                />
                <Grid container spacing={1} mt="5%">
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      startIcon={<FileUploadIcon />}
                      disabled={isLoading || !source || !fileName || !fileType}
                      onClick={() => setConfirm(true)}
                    >
                      {t("upload")}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog open={open} onClose={handleReset} maxWidth="lg" fullWidth>
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("upload_document")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={handleReset}
                  edge="end"
                  sx={{ color: "red" }}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <DialogContent dividers>
              <CDNFileForm
                isMobile={isMobile}
                request={request}
                tags={tags}
                location={location}
                source={source}
                downloadURL={downloadURL}
                blobURL={blobURL}
                fileName={fileName}
                fileType={fileType}
                documentProperties={documentProperties}
                tagSelectionOpen={tagSelectionOpen}
                selectedTag={selectedTag}
                handleDocumentPropertyChange={handleDocumentPropertyChange}
                handleFileChange={handleFileChange}
                handleChange={handleChange}
                handleOpen={handleOpen}
                handleClose={handleClose}
                handleFileClick={handleFileClick}
              />
              <Grid item container xs={12} spacing={1} mt="3%">
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<FileUploadIcon />}
                    disabled={isLoading || !source || !fileName || !fileType}
                    onClick={() => setConfirm(true)}
                  >
                    {t("upload")}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          )}
        </Dialog>
      )}

      <NewCertConfirm
        confirm={confirm}
        setConfirm={setConfirm}
        handleGenerate={handleGenerate}
      />

      <NewRequestConfirm
        approval={approval}
        setApproval={setApproval}
        handleSendApprovalRequest={handleSendApprovalRequest}
      />

      <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} />

      {uploadReceipt && (
        <CDNFileUploadReceipt
          receiptDialogOpen={receiptDialogOpen}
          handleResetUploadReceipt={handleResetUploadReceipt}
          uploadReceipt={uploadReceipt}
        />
      )}

      {renderFileDialogs()}
    </>
  );
};

export default CDNFileGenerator;
