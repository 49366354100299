// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getClient = async (clientID) => {
  try {
    const clientDocRef = doc(db, "clientsdata", clientID);
    const clientDocSnapshot = await getDoc(clientDocRef);

    if (clientDocSnapshot.exists()) {
      const clientData = {
        id: clientDocSnapshot.id,
        ...clientDocSnapshot.data(),
      };
      console.log(clientData);
      return clientData;
    } else {
      console.error("No such client found!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching client:", error);
    return null;
  }
};

export default getClient;
