// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getDataKeyData = async (keyID) => {
  try {
    const keyDocRef = doc(db, "keydata", keyID);
    const keyDocSnap = await getDoc(keyDocRef);

    if (keyDocSnap.exists()) {
      const keyData = keyDocSnap.data();
      return {
        name: keyData.name || "",
        owner_uuid: keyData.owner_uuid || false,
        created_on: keyData.created_on || "",
      };
    } else {
      return {
        name: "",
        owner_uuid: false,
        created_on: "",
      };
    }
  } catch (e) {
    console.error("Error in getUserData:", e.message);
    return {
      name: "",
      owner_uuid: false,
      created_on: "",
    };
  }
};

export default getDataKeyData;
