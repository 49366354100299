// Firebase
import { db } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const editClientPolicy = async (client, documentClass, condition) => {
  try {
    const docRef = doc(
      db,
      "clientsdata",
      client.id,
      "agreements",
      documentClass
    );
    await updateDoc(docRef, { condition });
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};

export default editClientPolicy;
