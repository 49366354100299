// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// Material UI Components
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

// Material UI Icons
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TaskIcon from "@mui/icons-material/Task";
import DownloadIcon from "@mui/icons-material/Download";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FolderZipIcon from "@mui/icons-material/FolderZip";

// Components
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";
import { Puller } from "ui-components/Puller";

// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// Functions
import downloadXMLFile from "storage/downloadXMLFile";
import downloadFile from "storage/downloadFile";
import getUserContacts from "UserOperations/getUserContacts";
import downloadAsZip from "storage/downloadAsZip";

// A ---------------------------------------------------------------------- M

const CDNPreservationProofDialog = ({ open, onClose, record }) => {
  console.log(record);

  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const [aicData, setAicData] = useState(null);

  useEffect(() => {
    const fetchUploadReceipt = async () => {
      try {
        if (record && record.txid) {
          const docRef = doc(db, "uploadReceipts", record.txid);
          const docSnapshot = await getDoc(docRef);

          if (docSnapshot.exists()) {
            const receiptData = docSnapshot.data();

            // Fetch creator contacts and email
            const creatorContacts = await getUserContacts(
              receiptData.creator_uuid
            );
            const creatorEmail = creatorContacts?.email || "N/A";

            // Update state with receipt data and email
            setUploadReceipt({ ...receiptData, creatorEmail });
          } else {
            console.log("No such document exists.");
          }
        }
      } catch (error) {
        console.error("Error fetching upload receipt: ", error);
      }
    };

    const fetchAICData = async () => {
      try {
        if (record && record.AIC_ID) {
          const aicDocRef = doc(db, "aicsdata", record.AIC_ID);
          const aicDocSnapshot = await getDoc(aicDocRef);
          if (aicDocSnapshot.exists()) {
            setAicData(aicDocSnapshot.data());
          }
        }
      } catch (error) {
        console.error("Error fetching AIC data: ", error);
      }
    };

    if (record) {
      fetchUploadReceipt();
      fetchAICData();
    }
  }, [record]);

  const handleResetUploadReceipt = () => {
    setReceiptDialogOpen(false);
  };

  const PreservationProofContent = (
    <Grid container spacing={2}>
      {/* AIC Section */}
      {record.AIC_ID && aicData && (
        <Grid item xs={12}>
          <Paper elevation={4} sx={{ padding: 2, marginBottom: 2 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid
                item
                container
                xs={12}
                md={0.5}
                justifyContent={isMobile ? "center" : "flex-start"}
              >
                <CheckCircleIcon sx={{ color: "green" }} fontSize="large" />
              </Grid>
              <Grid item xs={12} md={8.5}>
                <Typography>
                  L' Archival Index Collection (AIC UniSincro) è stato assegnato
                  al documento, firmato digitalmente e marcato temporalmente.
                </Typography>
              </Grid>
              <Grid item container xs={12} md={3} spacing={1}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<DownloadIcon />}
                    onClick={() =>
                      downloadXMLFile(
                        `AICFiles/${record.AIC_ID}/${record.AIC_ID}_AIC.xml`,
                        "AIC",
                        record.AIC_ID
                      )
                    }
                  >
                    Download AIC
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={<DownloadIcon />}
                    onClick={() =>
                      downloadXMLFile(
                        `AICFiles/${record.AIC_ID}/${record.AIC_ID}_AIC_signed.xml.p7m`,
                        "AIC_signed",
                        record.AIC_ID
                      )
                    }
                  >
                    Download Signed AIC
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}

      {/* Indice Di Conservazione Section */}
      <Grid item xs={12}>
        <Paper elevation={4} sx={{ padding: 2, marginBottom: 2 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid
              item
              container
              xs={12}
              md={0.5}
              justifyContent={isMobile ? "center" : "flex-start"}
            >
              <CheckCircleIcon sx={{ color: "green" }} fontSize="large" />
            </Grid>
            <Grid item xs={12} md={8.5}>
              <Typography>
                L' Indice Di Conservazione (IdC UniSincro) è stato firmato
                digitalmente.
              </Typography>
            </Grid>
            <Grid item container xs={12} md={3} spacing={1}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<DownloadIcon />}
                  onClick={() =>
                    downloadXMLFile(
                      `${record.uploadID}/${record.id}_IdC.xml`,
                      "IdC",
                      record.id
                    )
                  }
                >
                  Download IdC
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  startIcon={<DownloadIcon />}
                  onClick={() =>
                    downloadXMLFile(
                      `${record.uploadID}/${record.id}_IdC_signed.xml.p7m`,
                      "IdC_signed",
                      record.id
                    )
                  }
                >
                  Download Signed IdC
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Files Section */}
      <Grid item xs={12}>
        <Paper elevation={4} sx={{ padding: 2, marginBottom: 2 }}>
          <Grid container spacing={2}>
            {/* File Section */}
            <Grid item xs={12}>
              <Grid item container xs={12} alignItems="center" spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  md={0.5}
                  justifyContent={isMobile ? "center" : "flex-start"}
                >
                  <TaskIcon sx={{ color: "green" }} fontSize="large" />
                </Grid>
                <Grid item container xs={12} md={8.5} direction="column">
                  <Grid item>
                    <Typography>Nome File: {record.nomeFile}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Descrizione: File Dati</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Mime-Type: {record.formatoFile}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={3}
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      startIcon={<DownloadIcon />}
                      onClick={() =>
                        downloadFile(record.data.attachment, record.nomeFile)
                      }
                    >
                      {t("download")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Gluer Section */}
            <Grid item xs={12}>
              <Grid item container xs={12} alignItems="center" spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  md={0.5}
                  justifyContent={isMobile ? "center" : "flex-start"}
                >
                  <TaskIcon sx={{ color: "green" }} fontSize="large" />
                </Grid>
                <Grid item container xs={12} md={8.5} direction="column">
                  <Grid item>
                    <Typography>Nome File: gluer.xml</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Descrizione: File Parametri</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Mime-Type: application/xml</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={3}
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      startIcon={<DownloadIcon />}
                      onClick={() =>
                        downloadXMLFile(
                          `${record.uploadID}/${record.id}_gluer.xml`,
                          "gluer",
                          record.id
                        )
                      }
                    >
                      {t("download")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Metadata Section */}
            <Grid item xs={12}>
              <Grid item container xs={12} alignItems="center" spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  md={0.5}
                  justifyContent={isMobile ? "center" : "flex-start"}
                >
                  <TaskIcon sx={{ color: "green" }} fontSize="large" />
                </Grid>
                <Grid item container xs={12} md={8.5} direction="column">
                  <Grid item>
                    <Typography>Nome File: metadata.xml</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Descrizione: File Metadati</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>Mime-Type: application/xml</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  md={3}
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      startIcon={<DownloadIcon />}
                      onClick={() =>
                        downloadXMLFile(
                          `${record.uploadID}/${record.id}_metadata.xml`,
                          "metadata",
                          record.id
                        )
                      }
                    >
                      {t("download")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item container xs={12} spacing={1}>
        {/* Upload Receipt Button */}
        <Grid item xs={12} sm={6}>
          <Button
            startIcon={<FileOpenIcon />}
            variant="contained"
            fullWidth
            onClick={() => setReceiptDialogOpen(true)}
          >
            Open Upload Receipt
          </Button>
        </Grid>

        {/* Download As ZIP Button */}
        <Grid item xs={12} sm={6}>
          <Button
            startIcon={<FolderZipIcon />}
            variant="contained"
            fullWidth
            onClick={() => downloadAsZip(record.uploadID)}
          >
            Download As ZIP
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: "5%" }}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              mt="5%"
              mb="10%"
            >
              <Grid item>
                <Typography variant="h6" fontWeight="bold">
                  {t("preservation_proof")}
                </Typography>
              </Grid>
            </Grid>
            {PreservationProofContent}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("preservation_proof")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  onClick={onClose}
                  edge="end"
                  sx={{ color: "red" }}
                  aria-label={t("close")}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{PreservationProofContent}</DialogContent>
        </Dialog>
      )}

      {/* Upload Receipt Dialog */}
      {receiptDialogOpen && uploadReceipt && (
        <CDNFileUploadReceipt
          receiptDialogOpen={receiptDialogOpen}
          handleResetUploadReceipt={handleResetUploadReceipt}
          uploadReceipt={uploadReceipt}
        />
      )}
    </>
  );
};

CDNPreservationProofDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default CDNPreservationProofDialog;
