// Firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const getTagInfo = async (tagID) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const tagSnapshot = await getDoc(tagRef);

    if (tagSnapshot.exists()) {
      const tagData = tagSnapshot.data();
      return {
        id: tagSnapshot.id,
        ...tagData,
      };
    }

    return null;
  } catch (error) {
    console.error("Error in getTagInfo:", error);
    return null;
  }
};

export default getTagInfo;
