// Firebase
import { db } from "config/firebase";
import { collection, query, where, getDocs, doc } from "firebase/firestore";

// Functions
import fetchSignature from "./fetchSignature";

// A ---------------------------------------------------------------------- M

const fetchRecordsByType = async (
  tagID,
  type,
  isTagGroupMember,
  startDate,
  endDate
) => {
  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const signaturesRef = collection(tagRef, "signatures");

    let q;

    if (startDate || endDate) {
      const startDateUnixSecondsString = (
        startDate.getTime() / 1000
      ).toString();
      const endDateUnixSecondsString = (endDate.getTime() / 1000).toString();

      if (isTagGroupMember) {
        q = query(
          signaturesRef,
          where("type", "==", type),
          where("timestamp", ">=", startDateUnixSecondsString),
          where("timestamp", "<=", endDateUnixSecondsString)
        );
      } else {
        q = query(
          signaturesRef,
          where("type", "==", type),
          where("timestamp", ">=", startDateUnixSecondsString),
          where("timestamp", "<=", endDateUnixSecondsString)
        );
      }
    } else {
      if (isTagGroupMember) {
        q = query(signaturesRef, where("type", "==", type));
      } else {
        q = query(
          signaturesRef,
          where("type", "==", type),
          where("public", "==", true)
        );
      }
    }

    const snapshot = await getDocs(q);
    const signaturePromises = snapshot.docs.map(async (sig) => {
      const txid = sig.data().txid;
      const isPublic = sig.data().public || false;
      const signature = await fetchSignature(txid, isPublic);
      return signature;
    });

    const signatures = await Promise.all(signaturePromises);
    console.log(signatures);

    const signaturesFiltered = signatures.filter((obj) => obj !== undefined);
    return signaturesFiltered;
  } catch (e) {
    console.error("Error in fetchRecordsByType:", e.message);
    return null;
  }
};

export default fetchRecordsByType;
